@import "./assets/icons";
@import "./assets/variables";
@import "./assets/mixins";
@import "./assets/fonts";

* {
  margin: 0px;
  font-family: "Inter";
}

h1 {
  font-size: 50px;
  line-height: 50px;
  font-weight: 900;
}

h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 102.52%;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
}

h4 {
  font-weight: 900;
  font-size: 22px;
  line-height: 25px;
}

h5 {
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
}

p {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

button {
  color: $white;
  background-color: $red;
  height: 30px;
  padding: 8px 39px;
  font-weight: 600;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  &:disabled {
    color: $black;
    background-color: $grey;
    cursor: not-allowed;
  }
}

// vertical write on left side of landing pages
.vertical-text {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: absolute;
  top: 10%;
  left: 1%;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: none;
}

.FullLandingPage {
  min-height: 100vh;
  position: static;
}

.writeBackground {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  color: $white2;
  opacity: 0.5;
  text-align: center;
}

@include gt-md {
  h1 {
    font-size: 50px;
    line-height: 50px;
  }
  h3 {
    font-size: 25px;
    line-height: 30px;
  }
  h4 {
    font-size: 22px;
    line-height: 25px;
  }
  h5 {
    font-size: 20px;
    line-height: 22px;
  }
  p {
    font-size: 10px;
    line-height: 12px;
  }
  .vertical-text {
    display: block;
  }
}

@include gt-xl {
  h1 {
    font-size: 95px;
    line-height: 100px;
  }
  h3 {
    font-size: 50px;
    line-height: 60px;
  }
  h4 {
    font-size: 32px;
    line-height: 39px;
  }
  h5 {
    font-size: 30px;
    line-height: 32px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
  }
}

.eurosaga {
  &.icon-instagram.grey {
    &:hover {
      @extend .icon-instagram-black;
    }
  }
  &.icon-facebook.grey {
    margin-right: 10px;
    &:hover {
      @extend .icon-facebook-black;
    }
  }
}

@import "../../../../../assets/variables";
@import "../../../../../assets/mixins";

.shop {
  & .shop-item-in-cart {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin-bottom: 32px;

    & .cart-prod-image {
      height: 120px;
      max-width: 50px;
      object-fit: cover;
      object-position: center;

      @include gt-md {
        height: 150px;
        max-width: 80px;
        object-fit: cover;
        object-position: center;
      }
      @include gt-xl {
        height: 150px;
        max-width: 80px;
        object-fit: cover;
        object-position: center;
      }
    }

    & .cart-prod-details {
      box-sizing: border-box;
      width: 100%;
      padding-left: 16px;

      & .cart-prod-details-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0px;
      }

      & .delete-icon {
        margin-top: 12px;
        width: 18px;
        height: 18px;
      }

      & .cart-prod-details-brand {
        font-size: 16px;
        font-weight: light;
        line-height: 24px;
        margin-bottom: 8px;
      }

      & .cart-prod-details-other {
        font-size: 12px;
        font-weight: bold;
        opacity: 0.6;
        margin-bottom: 16px;
      }

      & .cart-prod-details-price {
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
}

@import "../../../assets/variables";
@import "../../../assets/mixins";

.ShopImagesAdmin {
  table {
    tbody {
      tr {
        td {
          .image {
            background-size: cover;
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  &.scheda {
    & .head {
      box-sizing: border-box;
      background-color: white;
      display: block;

      @include gt-md {
        box-shadow: 32px 11px 35.1652px rgba(118, 133, 137, 0.15);
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        margin-top: 120px;
        margin-bottom: 120px;
      }
      @include gt-xl {
        box-shadow: 32px 11px 35.1652px rgba(118, 133, 137, 0.15);
        border-radius: 64px;
        display: flex;
        flex-direction: row;
        margin-top: 120px;
        margin-bottom: 120px;
      }

      & .body {
        box-sizing: border-box;
        width: 100%;

        @include gt-md {
          padding-top: 54px;
          padding-left: 56px;
          width: 200%;
        }
        @include gt-xl {
          padding-top: 54px;
          padding-left: 56px;
          width: 200%;
        }

        & .flex {
          display: block;

          @include gt-md {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }
          @include gt-xl {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }

          & .first {
            flex: 1;

            @include gt-md {
              padding-right: 48px;
            }
            @include gt-xl {
              padding-right: 48px;
            }

            & .brand {
              font-size: 20px;
              font-weight: 300;
              padding-top: 32px;
              padding-bottom: 8px;

              @include gt-md {
                padding-top: 0px;
              }

              @include gt-xl {
                padding-top: 0px;
              }
            }

            & .title {
              font-size: 32px;
              font-weight: 600;
              margin-top: -8px;
              margin-bottom: 32px;
            }

            & .text {
              box-sizing: border-box;
              font-size: 20px;
              font-weight: 300;
              line-height: 1.5;
              margin-bottom: 32px;

              @include gt-md {
                padding-right: 32px;
              }

              @include gt-xl {
                padding-right: 32px;
              }
            }

            & .ingredienti {
              font-size: 16px;
              font-weight: 300;
              margin-bottom: 32px;
              line-height: 1.5;

              & b {
                font-weight: 600;
              }
            }

            & .end-date {
              margin-bottom: 16px;
              & .end-date-title {
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 0px;

                @include gt-md {
                  margin-bottom: 8px;
                }
                @include gt-xl {
                  margin-bottom: 8px;
                }
              }

              & .end-date-value {
                font-size: 16px;
                font-weight: 600;
              }
            }

            & .cars {
              margin-top: 24px;
              .car-tag {
                background-color: #f1f1f1;
                border-radius: 8px;
                display: inline-block;
                box-sizing: border-box;
                padding: 8px;
                margin-right: 16px;
                margin-bottom: 16px;

                & b {
                  font-weight: 600;
                }
              }
            }
          }

          & .second {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            padding-bottom: 54px;

            @include gt-md {
              display: flex;
              flex-direction: column;
              align-items: center;
              flex: 0.5;
              padding-right: 100px;
              padding-bottom: 0px;
            }
            @include gt-xl {
              display: flex;
              flex-direction: column;
              align-items: center;
              flex: 0.5;
              padding-right: 100px;
              padding-bottom: 0px;
            }

            & .title-formats {
              font-size: 14px;
              font-weight: 400;
              margin-top: 16px;
              margin-bottom: 8px;
            }

            & .input-format {
              display: inline-flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: white;
              box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.14);
              width: 72px;
              height: 72px;
              margin-left: 8px;
              margin-right: 8px;
              border-radius: 16px;

              &.active {
                opacity: 0.4;
              }

              & p {
                font-size: 16px;
              }
            }

            & .details {
              margin: auto;
              margin-top: 40px;
              width: 70%;
              display: flex;
              flex-direction: column;
              align-items: center;

              & .price {
                font-size: 32px;
                font-weight: 500;
                letter-spacing: -0.4px;
                margin-top: 40px;

                @include gt-md {
                  margin-bottom: 16px;
                }
                @include gt-xl {
                  margin-bottom: 16px;
                }
              }

              & .taxes {
                font-size: 12px;
                font-weight: 400;
                opacity: 0.7;
                margin-bottom: 24px;

                @include gt-md {
                  margin-bottom: 32px;
                }
                @include gt-xl {
                  margin-bottom: 32px;
                }
              }

              & .prod-add-to-cart {
                box-sizing: border-box;
                width: 100%;
                margin: auto;
                margin-top: 8px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 18px;
                padding-bottom: 20px;
                background-color: $red;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                cursor: pointer;
                transition: all 0.3s;

                &.active {
                  background-color: #05a357;

                  &:hover {
                    opacity: 1;
                  }
                }

                &:hover {
                  opacity: 0.7;
                }

                @include gt-xl {
                  width: 70%;
                  margin-left: 15%;
                }

                & p {
                  font-size: 18px;
                  color: white;
                  font-weight: 400;
                  margin-right: 12px;
                  text-transform: uppercase;
                  margin-top: 4px;
                }

                & .prod-add-to-cart-icon {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  object-position: center center;
                  fill: white;

                  & path,
                  & g {
                    fill: white;
                    stroke: white;
                  }
                }
              }
            }
          }
        }

        & .notes {
          font-size: 12px;
          font-weight: 300;
          width: 80%;
          margin: auto;
          margin-top: 10px;
          line-height: 1.5;
          letter-spacing: 0.2px;
          text-align: center;

          & b {
            font-weight: 500;
          }
        }
      }
    }
    & .schedaHeader {
      width: 100%;
      background-color: #f8f8f8;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @include gt-md {
        height: 100%;
        border-radius: 40px 0px 132px 40px;
      }

      @include gt-xl {
        height: 100%;
        border-radius: 54px 0px 124px 54px;
      }

      & .image {
        width: 180%;
        height: 60vh;
        margin-top: 80px;
        object-fit: contain;

        @include gt-md {
          width: 180%;
          height: 180%;
          margin-top: 0px;
          object-fit: contain;
        }

        @include gt-xl {
          width: 180%;
          height: 180%;
          margin-top: 0px;
          object-fit: contain;
        }
      }
    }

    & .loginAlert {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      & .shadow {
        position: absolute;
        width: 100vw;
        height: 100vh;
      }

      & .alertBody {
        width: 100%;
        max-width: 600px;
        background-color: white;
        position: relative;
        padding: 24px;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
        border-radius: 16px;
        margin-left: 0px;
        margin-right: 0px;

        @include gt-md {
          padding: 40px;
          padding-left: 72px;
          padding-right: 72px;
        }

        @include gt-xl {
          padding: 40px;
          padding-left: 72px;
          padding-right: 72px;
        }

        & .alertFlex {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          & .logo {
            margin-top: 16px;
            height: 32px;
          }

          & .flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            margin-bottom: 32px;
          }

          & h2 {
            font-size: 24px;
            margin-top: 40px;
            margin-bottom: 32px;
            width: 100%;
            text-align: left;
          }

          & p {
            font-size: 16px;

            & a {
              color: black;
              text-decoration: none;
            }

            &.privacy {
              text-align: center;
              font-size: 12px;
              line-height: 1.5;
              margin-bottom: 16px;
            }
          }

          & .cta {
            width: 100%;
            margin-top: 8px;
            padding-top: 12px;
            padding-bottom: 14px;
            background-color: $red;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            transition: opacity 0.3s;
            margin-bottom: 24px;
            box-sizing: border-box;

            &:hover {
              opacity: 0.7;
            }

            & p {
              font-size: 16px;
              color: white;
              font-weight: 500;
              margin-right: 8px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

@import "../../assets/variables";
@import "../../assets/mixins";

.Admin {
  margin-top: 70px;
  padding: 20px 30px;
  .AdminMenu {
    ul {
      padding: 0;
      display: flex;
      list-style: none;
      li {
        margin-right: 20px;
        a {
          &.active {
            color: $red;
          }
          text-decoration: none;
          color: $black;
          text-transform: uppercase;
        }
      }
    }
  }
  .AdminPageContent {
    .newButton {
      margin: 10px 0px;
    }
    table {
      border: solid 1px #ddeeee;
      border-collapse: collapse;
      border-spacing: 0;
      font: normal 13px Arial, sans-serif;
      thead {
        th {
          background-color: #ddefef;
          border: solid 1px #ddeeee;
          color: #336b6b;
          padding: 10px;
          text-align: left;
          text-shadow: 1px 1px 1px #fff;
        }
      }
      tbody {
        td {
          border: solid 1px #ddeeee;
          color: #333;
          padding: 10px;
          text-shadow: 1px 1px 1px #fff;
          .fa-edit {
            cursor: pointer;
          }
          .fa-trash {
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.shop {
  .footer {
    width: 100%;
    background-color: #262626;
    padding-top: 64px;
    margin-top: 64px;

    & h3 {
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 16px;
    }

    & p {
      color: rgba(255, 255, 255, 0.7);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    & a {
      color: white;
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    & .col {
      width: 25%;
      display: inline-block;
      vertical-align: top;
    }

    & .underFooter {
      padding-top: 120px;
      padding-bottom: 24px;
      display: flex;
      justify-content: center;
    }

    .logoCards {
      width: 200px;
    }

    & .collapse {
      & a {
        text-decoration: none;
        color: #000;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & p {
          margin-top: 0;
          padding-top: 0;
          font-weight: bold;
          color: white;
        }

        & img {
          width: 24px;
          height: 24px;
        }
      }

      & .details {
        display: flex;
        flex-direction: column;

        & p {
          width: 100%;
          display: block;
        }

        & a {
          color: white;
          width: 100%;
          display: block;
          font-size: 14px;
        }
      }

      & .details,
      .show,
      .hide:target {
        display: none;
      }
      & .hide:target + .show,
      .hide:target ~ .details {
        display: flex;
      }
    }
  }
}

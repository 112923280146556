.shop {
  &.shop-v-middle {
    & .wrapper-count-icon {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      &.cart {
        margin-bottom: 0px;
      }

      & .counter-icon {
        width: 40px;
        height: 40px;
      }
    }

    & .counter-value {
      width: 54px;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      line-height: initial;
      margin-top: -24px;
    }
  }

  &.shop-v-middle.cart {
    & .wrapper-count-icon {
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      &.cart {
        margin-bottom: 0px;
      }

      & .counter-icon {
        width: 24px;
        height: 24px;
      }
    }

    & .counter-value {
      width: 54px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: initial;
      margin-top: -24px;
    }
  }
}

@import "../../assets/variables";

.GenericInput.column {
  flex-direction: column;
}
.GenericInput {
  input.form-control {
    padding-left: 37px;
  }
  textarea.form-control {
    padding-left: 37px;
    padding-top: 37px;
    height: 301px;
  }
  .form-check-label {
    display: flex;
    font-size: 12px;
    line-height: 13px;
    align-content: center;
    align-items: center;
    letter-spacing: 0.12em;
    text-transform: none;
    span {
      font-family: "Venus Bold";
      cursor: pointer;
    }
  }
  img {
    height: 100px;
    width: 100px;
  }
  .choicesContainer {
    display: flex;
    flex-direction: row;
    .form-check {
      padding-right: 20px;
    }
  }
  .arrayRow {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    .childField {
      margin-right: 20px;
    }
  }
}

.styled-checkbox,
.styled-radio {
  position: absolute;
  opacity: 0;
  & + label {
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    line-height: 13px;
    align-items: center;
    letter-spacing: 0.12em;
    color: green;
    margin-bottom: 10px;
  }
  // Box.
  & + label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: white;
    border: 1px solid #0f5866;
    border-radius: 30px;
  }
  // Box hover
  &:hover + label:before {
    background: green;
  }
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  // Box checked
  &:checked + label:before {
    background: green;
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
}

.form-check.required {
  .styled-radio {
    & + label:before {
      border: solid 1px $red;
    }
  }
}

.blurred.touched.form-control:invalid,
.form-control.invalid {
  border: solid 1px $red;
  &:label {
    color: red;
  }
}

.label.red {
  color: $red2 !important;
}

.labelsRow {
  display: flex;
  justify-content: space-between;
  .errorLabel {
    display: flex;
    font-size: 15px;
    line-height: 120%;
    color: $red2;
    align-items: flex-end;
  }
}

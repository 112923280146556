@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  &.profilePage {
    margin-top: 24px;
    & .flex {
      display: block;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      @include gt-md {
        display: flex;
      }
      @include gt-xl {
        display: flex;
      }

      & .divider {
        width: 100%;
        height: 0.5px;
        background-color: black;
        margin-bottom: 16px;

        @include gt-md {
          margin-bottom: 16px;
        }
        @include gt-xl {
          margin-bottom: 16px;
        }
      }

      & .sidebar {
        width: 100%;
        max-width: 100%;
        margin-bottom: 40px;
        margin-top: 40px;
        box-sizing: border-box;
        padding-right: 16px;

        @include gt-md {
          max-width: 350px;
        }
        @include gt-xl {
          max-width: 350px;
        }

        & ul {
          padding: 0;
          margin: 0;
          list-style: none;

          & li {
            list-style: none;

            & p {
              font-size: 16px;
              margin-bottom: 16px;

              @include gt-md {
                margin-bottom: 16px;
              }
              @include gt-xl {
                margin-bottom: 16px;
              }

              & a {
                text-decoration: none;
                color: black;
              }
            }
          }
        }
      }

      & .page {
        box-sizing: border-box;
        padding: 0px;
        flex: 1;

        @include gt-md {
          padding: 24px;
        }
        @include gt-xl {
          padding: 24px;
        }

        & .section {
          margin-top: 32px;
        }

        & .option {
          width: 100%;
          box-sizing: border-box;

          &:nth-child(odd) {
            padding-right: 0px;

            @include gt-md {
              width: calc(100% - 8px);
              padding-right: 8px;
            }
            @include gt-xl {
              width: calc(100% - 8px);
              padding-right: 8px;
            }
          }

          &:nth-child(even) {
            padding-left: 0px;

            @include gt-md {
              width: calc(100% - 8px);
              padding-left: 8px;
            }
            @include gt-xl {
              width: calc(100% - 8px);
              padding-left: 8px;
            }
          }

          @include gt-md {
            width: 50%;
          }
          @include gt-xl {
            width: 50%;
          }

          & p {
            margin-top: 16px;
            font-size: 16px;
          }
        }

        & h2 {
          margin-bottom: 8px;
        }

        & h3 {
          margin-bottom: 16px;
        }

        & p {
          font-size: 16px;
          line-height: 1.4;
        }

        & .cta {
          width: 100%;
          margin-top: 40px;
          padding-top: 20px;
          padding-bottom: 20px;
          background-color: $red;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          transition: opacity 0.3s;
          margin-bottom: 24px;
          box-sizing: border-box;

          @include gt-md {
            width: 33%;
          }

          @include gt-xl {
            width: 33%;
          }

          &:hover {
            opacity: 0.7;
          }

          & p {
            font-size: 16px;
            color: white;
            font-weight: 500;
            margin-right: 8px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
    &.confirmRegistration {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .logo {
            margin-top: 72px;
        }

        & .box {
            margin-top: 80px;
            margin-bottom: 80px;
            width: 100%;
            max-width: 600px;
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0px 0px 32px rgba(0,0,0,0.16);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px;
            box-sizing: border-box;

            & .icon {
                width: 88px;
                height: 88px;
                object-fit: contain;
                padding: 12px;
                border-radius: 50%;
                border: 4px solid #6FCF97;
                display: flex;
                justify-content: center;
                align-items: center;

                & img {
                    width: 64px;
                }
            }

            & h3 {
                margin-top: 24px;
            }

            & p {
                font-size: 16px;
                line-height: 1.5;
                text-align: center;
                margin-top: 16px;
            }
        }
    }
}
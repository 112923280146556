.eurosaga {
  height: 35px;
  width: 30px;
  // background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.icon-menu {
  background-image: url("./icons/menu.svg");
}
.icon-facebook-black {
  background-image: url("./icons/facebook_black.svg");
}
.icon-instagram-black {
  background-image: url("./icons/instagram_black.svg");
}
.icon-facebook.white {
  background-image: url("./icons/facebook_white.svg");
}
.icon-instagram.white {
  background-image: url("./icons/instagram_white.svg");
}
.icon-facebook.grey {
  background-image: url("./icons/facebook_grey.svg");
}
.icon-instagram.grey {
  background-image: url("./icons/instagram_grey.svg");
}
.icon-news {
  background-image: url("./icons/news.svg");
}
.icon-close {
  background-image: url("./icons/close.svg");
}
.icon-search {
  background-image: url("./icons/search.svg");
}
.icon-termometro {
  background-image: url("./icons/termometro.svg");
}
.icon-plus {
  background-image: url("./icons/plus.svg");
}

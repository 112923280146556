@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  &.registrationPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .logo {
      margin-top: 72px;
      cursor: pointer;
    }

    & .box {
      margin-top: 80px;
      margin-bottom: 80px;
      width: 100%;
      max-width: 600px;
      border-radius: 0px;
      overflow: hidden;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

      & .toggle {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;

        & .tab {
          position: relative;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding-top: 24px;
          padding-bottom: 24px;
          opacity: 0.4;
          cursor: pointer;

          &.active {
            background-color: transparent;
            opacity: 1;

            &:nth-child(even) {
              border-radius: 0px 0px 0px 24px;
            }

            &:nth-child(odd) {
              border-radius: 0px 0px 24px 0px;
            }
          }

          & h3 {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
          }
        }

        & .background {
          background-color: #e7e7e7;
          position: absolute;
          width: 50%;
          height: 100%;
          transition: all 0.3s;

          &.left {
            transform: translateX(100%);
            border-radius: 0px 0px 0px 24px;
          }

          &.right {
            transform: translateX(0%);
            border-radius: 0px 0px 24px 0px;
          }
        }
      }

      & .box-body {
        padding: 24px;

        & label {
          display: block;
          font-size: 16px;
          margin-top: 24px;
          margin-bottom: 16px;
          font-weight: 500;
        }

        & p {
          font-size: 16px;
          margin-top: 8px;
        }

        & .checks {
          margin-top: 24px;
          margin-bottom: 16px;

          & div {
            margin-bottom: 8px;
          }

          & label {
            display: inline;
            font-size: 14px;
          }
        }

        & .cta {
          width: 100%;
          max-width: 200px;
          margin-top: 8px;
          padding-top: 16px;
          padding-bottom: 24px;
          background-color: $red;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          transition: opacity 0.3s;
          margin-bottom: 24px;
          box-sizing: border-box;

          &:hover {
            opacity: 0.7;
          }

          & p {
            font-size: 16px;
            color: white;
            font-weight: 500;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

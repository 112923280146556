@import "../../assets/variables";
@import "../../assets/mixins";

.Menu {
  background-color: $red;
  height: 100vh;
  padding: 20vh 0 30px 0;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  left: 0;
  .writeBackground {
    color: #de242f36;
    height: -webkit-fill-available;
    left: 0;
    bottom: 0;
    right: 0;
    font-weight: 900;
    font-family: "Shrimp";
    z-index: 1;
    opacity: 0.8;
    font-size: 100px;
    line-height: 120%;
    display: none;
    @include gt-sm {
      top: 20px;
      font-size: 120px;
      line-height: 190%;
    }
    @include gt-md {
      top: 30px;
      font-size: 150px;
      line-height: 150%;
    }
    @include gt-lg {
      top: 30px;
      font-size: 220px;
      line-height: 110%;
    }
    @include gt-xl {
      font-size: 300px;
      line-height: 100%;
    }
  }
  .eurosaga {
    height: 35px;
    width: 35px;
    position: relative;
    z-index: 20;
    &.icon-close {
      right: 13%;
      top: 20px;
      position: fixed;
    }
    &.icon-facebook {
      margin: 0 10px 0 0;
    }
    &.icon-instagram {
      margin: 0 0 0 10px;
    }
  }
  ul {
    position: relative;
    z-index: 20;
    list-style: none;
    text-align: center;
    padding: 0px;
    font-size: 30px;
    line-height: 60px;
    li {
      z-index: 20;
      cursor: pointer;
      font-weight: 900;
      color: $white;
      &:hover {
        // color:$grey2;
      }
    }
  }
  .row {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  @include gt-sm {
    // padding: 100px 0 0px 0;
    .eurosaga {
      height: 30px;
      width: 30px;
      &.icon-close {
        right: 13%;
        top: 50px;
      }
      &.icon-facebook {
        margin: 0 10px 0 0;
      }
      &.icon-instagram {
        margin: 0 0 0 10px;
      }
    }
    ul {
      font-size: 40px;
      line-height: 100px;
    }
    .row {
      margin-top: 70px;
    }
  }
  @include gt-md {
    padding: 10vh 0 0px 0;
    .eurosaga {
      &.icon-close {
        right: 13%;
        top: 50px;
      }
    }
    ul {
      font-size: 40px;
      line-height: 200%;
    }
    .row {
      margin-top: 10vh;
    }
  }
  @include gt-lg {
    // padding: 100px 0 0px 0;
    ul {
      font-size: 45px;
      line-height: 200%;
    }
  }
  @include gt-xl {
    padding-top: 150px;
    .eurosaga {
      height: 50px;
      width: 50px;
      &.icon-close {
        right: 13%;
        top: 50px;
      }
      &.icon-facebook {
        margin: 0 20px 0 0;
      }
      &.icon-instagram {
        margin: 0 0 0 20px;
      }
    }
    ul {
      font-size: 50px;
      line-height: 250%;
    }
    .row {
      margin-top: 30px;
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

main.Shop {
  margin-top: 0;
}

.shop {
  overflow: hidden;
  touch-action: manipulation;

  & .col.wM {
    margin-left: 0px;

    @include gt-md {
      margin-left: 16px;
    }
    @include gt-xl {
      margin-left: 16px;
    }
  }
}

.banner {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #24140a;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;

  @include gt-md {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @include gt-xl {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .flex {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;

    & .icon {
      width: 40px;
      height: 40px;
      margin-right: 2px;

      @include gt-md {
        width: 48px;
        height: 48px;
      }
      @include gt-xl {
        width: 48px;
        height: 48px;
      }
    }

    & .label {
      color: white;
      font-size: 14px;

      @include gt-md {
        font-size: 16px;
      }
      @include gt-xl {
        font-size: 16px;
      }
    }
  }
}

.carousel {
  overflow: visible;
  position: relative;
  width: calc(100% + 32px);

  & .carousel__slider {
    padding-left: 16px;
    margin-left: -16px;
  }

  & .carousel__slide {
    margin: 0;
    margin-bottom: 32px !important;
    overflow: visible;
  }

  & .carousel__slide--hidden {
    display: none;
  }

  & .backButtonCarouselBestOf {
    position: absolute;
    left: -32px;
    top: 34%;
    width: auto;
    padding: 24px;
    height: 64px;
    border-radius: 32px;
    width: 64px;
    background-color: rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px) brightness(100%) saturate(180%);

    & img {
      margin-top: -2px;
      margin-left: -2px;
    }

    &.forBrands {
      top: 15%;
    }
  }

  & .nextButtonCarouselBestOf {
    position: absolute;
    right: -16px;
    top: 34%;
    width: auto;
    padding: 24px;
    height: 64px;
    border-radius: 32px;
    width: 64px;
    background-color: rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px) brightness(100%) saturate(180%);

    & img {
      margin-top: -2px;
      margin-left: -2px;
    }

    &.forBrands {
      top: 15%;
    }
  }
}

.shop {
  .bestOf {
    width: 100%;
    padding-bottom: 16px;

    @include gt-md {
      margin-left: 16px;
    }
    @include gt-xl {
      margin-left: 16px;
    }

    & h2 {
      margin-left: 16px;
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 28px;

      &.forDesktop {
        margin-left: 0px;
      }
    }

    .itemsWrapper {
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
    }

    .bestPost {
      overflow: visible;
      width: 75%;
      margin-left: 16px;
      margin-bottom: 32px;
      display: inline-block;
      background-color: white;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      vertical-align: top;
      height: 366px;

      &.forDesktop {
        width: calc(100% - 16px);
        box-sizing: border-box;
        margin-right: 0px;
        margin-left: 0px;
        height: auto;
      }

      & .image {
        width: 100%;
        padding-top: 80%;
        background-color: #eaeaea;
        background-size: cover;
        background-position: center center;

        &.left {
          border-radius: 16px 16px 96px 0px;
        }

        &.right {
          border-radius: 16px 16px 0px 96px;
        }
      }

      & h3 {
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 24px;
        font-weight: bold;
        margin-top: 16px;
      }

      & p {
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
        opacity: 0.7;
        margin-bottom: 16px;
        white-space: pre-wrap;
      }
    }
  }
}

.Spinner.loading .ldsEllipsis div {
  display: none;
}

@import "./variables";

@mixin row-field($columns: 2) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    width: #{100% / $columns};
  }
}

// tablet compresi in su
@mixin gt-xs() {
  @media screen and (min-width: #{map-get($breakpoints,xs)+1}px) {
    @content;
  }
}

// tablet compresi in su
@mixin gt-sm() {
  @media screen and (min-width: #{map-get($breakpoints,sm)+1}px) {
    @content;
  }
}

// pc in su
@mixin gt-md() {
  @media screen and (min-width: #{map-get($breakpoints,md)+1}px) {
    @content;
  }
}

@mixin gt-lg() {
  @media screen and (min-width: #{map-get($breakpoints,lg)+1}px) {
    @content;
  }
}

@mixin gt-xl() {
  @media screen and (min-width: #{map-get($breakpoints,xl)+1}px) {
    @content;
  }
}

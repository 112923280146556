@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  touch-action: manipulation;

  &.checkoutPage {
    & .rightBackground {
      width: 35%;
      height: 100vh;
      background-color: #f1f1f1;
      position: fixed;
      right: 0;
      top: 0;
      display: none;

      @include gt-md {
        display: block;
      }

      @include gt-xl {
        display: block;
      }
    }
    & .footer {
      margin: 0;
      z-index: 1000;
    }
    & .flex {
      width: 100%;
      display: block;
      justify-content: space-between;

      @include gt-md {
        display: flex;
      }

      @include gt-xl {
        display: flex;
      }

      &.vertical {
        align-items: center;
      }

      & .rightCol {
        width: calc(100% + 32px);
        height: 100vh;
        background-color: #f1f1f1;
        position: relative;
        right: 0;
        padding-top: 8px;
        padding-right: 24px;
        padding-left: 16px;
        box-sizing: border-box;
        margin-left: -16px;

        @include gt-md {
          width: 30%;
          position: fixed;
          margin-left: 0px;
        }

        @include gt-xl {
          width: 30%;
          position: fixed;
          margin-left: 0px;
        }

        & .cart {
          width: 100%;
          max-height: 40vh;
          overflow-y: scroll;

          & .itemCart {
            margin-top: 16px;
            display: flex;
            align-items: center;

            & .flex {
              align-items: center;
            }

            & .info {
              display: flex;
              align-items: center;

              & .wrapperImageProd {
                position: relative;

                & .imageProd {
                  width: 100px;
                  height: 140px;
                  object-fit: contain;
                  object-position: center;
                }

                & .notification {
                  background-color: $red;
                  color: white;
                  width: 20px;
                  height: 20px;
                  font-size: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 10px;
                  position: absolute;
                  top: 4px;
                  right: 12px;
                }
              }
            }

            & h2 {
              font-size: 14px;
              font-weight: 600;
            }

            & h3 {
              font-size: 12px;
              font-weight: 300;
            }

            & .price {
              font-size: 19px;
              font-weight: 600;
            }
          }
        }

        & p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;

          &.data {
            font-weight: 600;
            color: #3f3f3f, 100%;
          }
        }

        & .divider {
          width: 100%;
          height: 1px;
          background-color: black;
          opacity: 0.16;
          margin-top: 24px;
          margin-bottom: 24px;
        }

        & .cta {
          width: 100%;
          margin-top: 40px;
          padding-top: 22px;
          padding-bottom: 22px;
          background-color: $red;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          transition: opacity 0.3s;
          margin-bottom: 24px;
          box-sizing: border-box;
          margin-top: 30px;
          margin-left: 0px;

          @include gt-md {
            width: 33%;
            margin-left: 8px;
          }

          @include gt-xl {
            width: 33%;
            margin-left: 8px;
          }

          &.code {
            padding-top: 16px;
            padding-bottom: 16px;
          }

          &:hover {
            opacity: 0.7;
          }

          & p {
            font-size: 16px;
            color: white;
            font-weight: 500;
            margin-right: 8px;
          }
        }
      }
      & .body {
        width: 100%;
        padding-top: 56px;
        background-color: white;
        padding-bottom: 80px;
        box-sizing: border-box;
        padding-left: 0px;
        padding-right: 0px;
        min-height: 100vh;

        @include gt-md {
          width: 70%;
          padding-right: 80px;
        }

        @include gt-xl {
          width: 70%;
          padding-right: 128px;
        }

        & .informazione {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          padding-right: 0px;

          @include gt-md {
            padding-right: 24px;
          }

          @include gt-xl {
            padding-right: 24px;
          }

          & .indexPage {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;

            & li {
              & p {
                font-size: 18px;
                font-weight: 300;
                line-height: 1.4;
                margin-right: 4px;

                &.active {
                  font-weight: 500;
                }
              }
            }
          }

          & .section {
            width: 100%;
            margin-top: 32px;
          }

          & .option {
            width: 100%;
            box-sizing: border-box;

            &:nth-child(odd) {
              padding-right: 0px;

              @include gt-md {
                width: calc(100% - 8px);
                padding-right: 8px;
              }
              @include gt-xl {
                width: calc(100% - 8px);
                padding-right: 8px;
              }
            }

            &:nth-child(even) {
              padding-left: 0px;

              @include gt-md {
                width: calc(100% - 8px);
                padding-left: 8px;
              }
              @include gt-xl {
                width: calc(100% - 8px);
                padding-left: 8px;
              }
            }

            @include gt-md {
              width: 50%;
            }
            @include gt-xl {
              width: 50%;
            }

            & p {
              margin-top: 16px;
              font-size: 16px;
            }
          }

          & h2 {
            margin-bottom: 8px;
          }

          & h3 {
            margin-bottom: 16px;
          }

          & p {
            font-size: 16px;
            line-height: 1.4;
          }

          & .backTo {
            margin-top: 112px;
            opacity: 0.7;
          }

          & .cta {
            width: 100%;
            margin-top: 24px;
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: $red;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            transition: opacity 0.3s;
            margin-bottom: 24px;
            box-sizing: border-box;

            @include gt-md {
              width: 33%;
              margin-top: 112px;
            }

            @include gt-xl {
              width: 33%;
              margin-top: 112px;
            }

            &:hover {
              opacity: 0.7;
            }

            & p {
              font-size: 16px;
              color: white;
              font-weight: 500;
              margin-right: 8px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
    & .row {
        display: block;
        margin-top: 24px;

        @include gt-md {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        @include gt-xl {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        & .col {
            box-sizing: border-box;
            width: 100%;

            &:nth-child(odd) {
                padding-left: 0px;

                @include gt-md {
                    padding-left: 0px;
                }
                @include gt-xl {
                    padding-left: 0px;
                }
            }

            &:nth-child(even) {
                padding-right: 0px;

                @include gt-md {
                    padding-right: 0px;
                }
                @include gt-xl {
                    padding-right: 0px;
                }
            }
    
            @include gt-md {
                width: 100%;
            }
            @include gt-xl {
                width: 50%;
            }
        }
    }

    & .widget {
        box-sizing: border-box;
        position: relative;
        border-radius: 8px;
        padding-bottom: 0;
        display: inline-block;

        &.large {
            position: relative;
            width: calc(100vw - 32px);
            padding-top: 100vw;

            @include gt-md {
                width: 100%;
                padding-top: 100%;
            }
            @include gt-xl {
                width: 100%;
                padding-top: 100%;
            }
        }
    
        &.medium {
            position: relative;
            width: calc(100vw - 32px);
            padding-top: 50vw;

            @include gt-md {
                width: 100%;
                padding-top: 50%;
            }
            @include gt-xl {
                width: 100%;
                padding-top: 50%;
            }
        }
    
        &.small {
            position: relative;
            width: calc(50% - 8px);
            padding-top: 50%;

            &:nth-child(odd) {
                margin-right: 16px;

                @include gt-md {
                    width: calc(50% - 24px);
                    margin-right: 16px;
                }
                @include gt-xl {
                    margin-right: 16px;
                    width: calc(50% - 24px);
                }
            }

            .content {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0px;
                width: calc(100% - 0px);
                height: calc(100% - 16px);
                border-radius: 8px;
                background-color: #ccc;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
    
                @include gt-md {
                    border-radius: 16px;
                    left: 16px;
                    width: calc(100% - 16px);

                    &:nth-child(odd) {
                        width: calc(100% - 0px);
                    }
                }
                @include gt-xl {
                    border-radius: 16px;
                    left: 16px;
                    width: calc(100% - 16px);
                }

                
            }
        }

        & .content {
            position: absolute;
            top: 0;
            left: 0px;
            width: calc(100% - 0px);
            height: calc(100% - 16px);
            border-radius: 8px;
            background-color: #ccc;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;

            @include gt-md {
                border-radius: 16px;
            }
            @include gt-xl {
                border-radius: 16px;
            }
        }
    }
}
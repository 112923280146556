@import "../../../assets/variables";
@import "../../../assets/mixins";

.Mail {
  @include row-field();
  background-color: $red;
  color: $white;
  text-align: left;
  height: auto;
  .leftCol {
    box-sizing: border-box;
    width: 100%;
    padding: 50px map-get($sidePadding, xs) 40px map-get($sidePadding, xs);
    h1 {
      text-align: left;
      margin-bottom: 30px;
    }
    h5 {
      margin-bottom: 40px;
    }
    input,
    textarea {
      background-color: $red3;
      color: $white2;
      border: none;
      border-radius: 6px;
      width: 100%;
      height: 40px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 17px;
      box-sizing: border-box;
      font-weight: 300;
      resize: none;
      padding-left: 7px;
    }
    textarea {
      height: 125px;
      padding-top: 15px;
    }
    ::placeholder {
      color: $grey2;
    }
    button {
      border: 1px solid $white;
      border-radius: 8px;
      font-weight: bold;
      margin-top: 10px;
      font-size: 13px;
      line-height: 17px;
      // height: 40px;
      padding: 9px 18px;
      height: auto;
      width: auto;
      line-height: 16px;
    }
  }
  .rightCol {
    display: none;
  }

  @include gt-sm {
    height: 100vh;
    height: auto;
    .leftCol {
      width: 50%;
      padding: 4.5% 0px 5% 4.5%;
      text-align: left;
      h1 {
        font-size: 45px;
        text-align: left;
        margin-bottom: 5%;
        line-height: 102%;
      }
      h5 {
        font-size: 15px;
        margin-bottom: 8%;
        line-height: 120%;
      }
      input,
      textarea {
        width: -webkit-fill-available;
        box-sizing: content-box;
        height: 40px;
        margin-bottom: 3%;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
      }
      textarea {
        padding-top: 10px;
        height: 75px;
      }
      button {
        border: 2px solid $white;
        margin-top: 4%;
        font-size: 15px;
        line-height: 17px;
        height: 40px;
        padding: 0px 30px;
        box-sizing: content-box;
        border-radius: 10px;
      }
    }
    .rightCol {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: -50px;
    }
  }
  @include gt-md {
    .leftCol {
      padding: 4.5% 0px 5% 5%;
      h1 {
        font-size: 60px;
      }
      h5 {
        font-size: 16px;
      }
      input,
      textarea {
        width: 98%;
        height: 50px;
        font-size: 16px;
        line-height: 18px;
      }
      textarea {
        height: 180px;
      }
      button {
        font-size: 16px;
        line-height: 18px;
        height: 40px;
      }
    }
    .rightCol {
      background-position-y: -40px;
    }
  }
  @include gt-lg {
    .leftCol {
      padding-left: map-get($sidePadding, lg);
      h1 {
        font-size: 80px;
      }
      h5 {
        font-size: 22px;
      }
      input,
      textarea {
        height: 50px;
        font-size: 20px;
        line-height: 24px;
      }
      textarea {
        height: 220px;
      }
      button {
        font-size: 18px;
        line-height: 20px;
        height: 50px;
      }
    }
    .rightCol {
      background-position-y: -180px;
    }
  }
  @include gt-xl {
    .leftCol {
      h1 {
        font-size: 100px;
      }
      h5 {
        font-size: 30px;
      }
      input,
      textarea {
        height: 70px;
        font-size: 24px;
        line-height: 25px;
      }
      textarea {
        height: 300px;
      }
      button {
        font-size: 21px;
        line-height: 25px;
        height: 60px;
      }
    }
    .rightCol {
      background-position-y: -250px;
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  &.feed {
    box-sizing: border-box;
    padding-top: 32px;

    & .main {
      box-sizing: border-box;
      padding: 8px;
      padding-top: 48px;

      @include gt-sm {
        padding: 8px;
      }
      @include gt-xl {
        padding: 8px;
      }

      & .index-page {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        color: #4f4f4f;

        & a {
          color: black;
          text-decoration: none;
        }
      }

      & .ordine-per-menu {
        width: calc(100% - 48px);
        box-sizing: border-box;
        position: absolute;
        top: 72px;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
        padding: 16px;
        z-index: 1000;

        & ul {
          padding: 0;
          list-style-type: none;

          & li {
            margin-top: 8px;
            margin-bottom: 8px;

            & p {
              cursor: pointer;

              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }

      & p {
        margin: 0;
        font-size: 18px;
      }

      & .flex-h-space-btw {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .flex-h-center {
          display: flex;
          flex-direction: row;
          align-items: center;

          & .label-icon-chevron {
            width: 12px;
            height: 12px;
            margin-left: 8px;
          }

          & .label-icon-filters {
            width: 18px;
            height: 18px;
            margin-left: 16px;
          }
        }
      }
    }
  }

  & .counter-prod-list {
    margin-top: 8px;
    margin-bottom: 64px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #4f4f4f;
  }

  &.catalogo-header {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 16px;
    position: relative;
    background-color: #222;
    background-size: cover;
    background-position: center center;

    @include gt-sm {
      padding-top: 300px;
      padding-bottom: 24px;
    }
    @include gt-xl {
      padding-top: 300px;
      padding-bottom: 24px;
    }

    & .catalogo-header-shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.32);
    }

    & .details {
      position: relative;
      box-sizing: border-box;
      box-sizing: border-box;
      padding-right: 16px;
      margin: auto;

      & .headline {
        font-size: 28px;
        font-weight: 600;
        color: white;
        margin-bottom: 0px;

        @include gt-sm {
          font-size: 54px;
          font-weight: 600;
          margin-bottom: 12px;
        }
        @include gt-xl {
          font-size: 54px;
          font-weight: 600;
          margin-bottom: 12px;
        }
      }

      & .subheadline {
        font-size: 16px;
        font-weight: 300;
        color: white;
        max-width: 400px;
        line-height: 1.3;

        @include gt-sm {
          font-size: 18px;
          font-weight: 300;
        }
        @include gt-xl {
          font-size: 18px;
          font-weight: 300;
        }
      }
    }
  }

  & .feed-mobile-filters {
    padding-top: 32px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 100000;
    transition: top 0.3s;

    &.hide-mobile-menu {
      top: 100%;
    }

    & .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & h2 {
        font-size: 32px;
      }

      & .icon {
        width: 24px;
        height: 24px;
        padding: 12px;
        padding-right: 0px;
      }
    }

    & .filterFlex {
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: row;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      padding: 16px;
      margin-bottom: 16px;
      border-top: 0.5px solid #000;
    }

    & .apply_filters {
      position: relative;
      text-decoration: none;
      color: white;
      background-color: #eb5757;
      width: calc(50% - 8px);
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 1px;
      display: block;
      padding-top: 20px;
      padding-bottom: 16px;
      border-radius: 16px;

      &.outlined {
        background-color: transparent;
        border: 2px solid #eb5757;
        color: black;
      }

      &:nth-child(odd) {
        margin-left: 8px;
      }

      &:nth-child(odd) {
        margin-right: 8px;
      }
    }

    & .filters_list {
      list-style: none;
      padding: 0;
      padding-top: 32px;
      & li {
        margin-bottom: 40px;

        & a {
          text-decoration: none;
          color: #000;
          font-size: 24px;
          font-weight: bold;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & p {
            margin-top: 0;
            padding-top: 0;
            font-weight: 500;
          }

          & img {
            width: 24px;
            height: 24px;
          }
        }

        & .details,
        .show,
        .hide:target {
          display: none;
        }
        & .hide:target + .show,
        .hide:target ~ .details {
          display: flex;
        }
      }
    }
  }

  .desktop-filters {
    width: 100%;
    background-color: #f8f7f5;
    padding-top: 10px;
    padding-bottom: 16px;
    overflow: visible;

    & ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
    }

    & li {
      position: relative;
      width: 100%;

      & .shop-profile-menu {
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;

        & .list-profile-menu {
          box-sizing: border-box;
          position: relative;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 24px;
          padding-right: 24px;
          width: 100%;

          & h3 {
            font-size: 16px;
            font-weight: 500;
          }

          & p {
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .nav {
    padding-top: 48px;
    padding-bottom: 24px;

    & h3 {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.products-container {
  padding: 0 0px;
  @include gt-sm {
    padding: 0 0px;
  }
  @include gt-xl {
    padding: 0 0px;
  }
}

.shop {
  & .input-text-wrapper {
    position: relative;
    width: 100%;
    & .input-icon-select {
      width: 24px;
      height: 24x;
      position: absolute;
      top: 26px;
      right: 12px;
    }
  }

  & .input-text {
    display: block;
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    height: 58px;
    margin-top: 8px;
    box-sizing: border-box;
  }

  & .input-select-wrapper {
    position: relative;
    width: 100%;
    & .input-icon-select {
      width: 24px;
      height: 24x;
      position: absolute;
      top: 26px;
      right: 12px;
    }
  }

  & .input-select {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 500;
    color: #444;
    line-height: 1.3;
    width: 100%;
    max-width: 97%;
    box-sizing: border-box;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #000;
    border-radius: 8px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f5f6f8;
    margin-top: 8px;
    height: 58px;

    &.forCheckout {
      max-width: 100%;
      border: solid 1px #e7e7e7;
      background-color: #ffffff;
      font-weight: 600;
    }
  }
}

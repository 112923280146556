@import "../../../assets/variables";
@import "../../../assets/mixins";

.Actions {
  background-color: $black5;
  color: $white;
  margin-bottom: map-get($parallaxHeightSectionSize, xs);
  padding-top: 25px;
  .leftColumn {
    height: 50vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .rightColumn {
    box-sizing: border-box;
    padding: 70px map-get($sidePadding, xs) 50px map-get($sidePadding, xs);
    text-align: left;
    h2 {
      margin-bottom: 20px;
      font-size: 23px;
    }
    p {
      margin-bottom: 20px;
      font-size: 16px;
    }
    .row {
      text-align: center;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        border: 1.3px solid $red;
        background-color: transparent;
        border-radius: 8px;
        font-weight: bold;
        width: auto;
        margin-bottom: 20px;
        height: 44px;
        padding: 10px 16px;
        font-size: 14px;
        &:hover {
          background: $red;
        }
      }
    }
  }
  @include gt-xs {
    .rightColumn {
      .row {
        button {
          padding: 13px 24px;
          font-size: 15px;
        }
      }
    }
  }
  @include gt-sm {
    padding: 40px 0px 40px 0px;
    margin-bottom: map-get($parallaxHeightSectionSize, sm);
    @include row-field();
    text-align: left;
    .leftColumn {
      height: auto;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .rightColumn {
      padding: 15% 4% 11% 8%;
      text-align: left;
      h2 {
        margin-bottom: 4%;
        line-height: 102%;
        font-size: 20px;
      }
      p {
        margin-bottom: 9%;
        line-height: 150%;
        font-size: 11px;
        font-weight: 300;
        &.details {
          line-height: 120%;
          font-size: 8px;
        }
      }
      .row {
        margin-bottom: 7%;
        text-align: left;
        justify-content: unset;
        button {
          display: block;
          padding: 8px 20px;
          height: 32px;
          width: auto;
          margin-bottom: 0px;
          font-size: 10px;
          border: 2px solid $red;
          &.primary {
            margin-right: 6%;
          }
        }
      }
    }
  }
  @include gt-md {
    padding: 60px 0px 60px 50px;
    margin-bottom: map-get($parallaxHeightSectionSize, md);
    .rightColumn {
      padding: 14% 13% 13% 6%;
      h2 {
        font-size: 21px;
      }
      p {
        font-size: 11px;
        &.details {
          font-size: 12px;
        }
      }
      .row {
        button {
          border-radius: 12px;
          padding: 10px 20px;
          height: 40px;
          font-size: 12px;
        }
      }
    }
  }
  @include gt-lg {
    padding: 100px 0px 100px 50px;
    margin-bottom: map-get($parallaxHeightSectionSize, lg);
    .rightColumn {
      padding: 9% 13% 8% 6%;
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
        &.details {
          font-size: 16px;
        }
      }
      .row {
        button {
          padding: 12px 30px;
          height: 50px;
          font-size: 16px;
        }
      }
    }
  }
  @include gt-xl {
    margin-bottom: map-get($parallaxHeightSectionSize, xl);
    .rightColumn {
      padding: 9% 13% 8% 6%;
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 22px;
        &.details {
          font-size: 20px;
        }
      }
      .row {
        button {
          padding: 16px 40px;
          height: 60px;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

@import "../../../assets/variables";
@import "../../../assets/mixins";

.Products {
  position: relative;
  padding: 80px 10px;
  height: 100vh;
  box-sizing: border-box;
  .writeBackground {
    z-index: 0;
    font-weight: 800;
    font-size: 180px;
    line-height: 300%;
    text-align: center;
    font-family: "Shrimp";
    height: -webkit-fill-available;
    transform: translateX(-25%) rotate(-90deg);
    display: none;
  }
  &.yellow {
    background-color: $yellow1;
    .writeBackground {
      color: $yellow2;
    }
  }
  &.red {
    background-color: $red4;
    .writeBackground {
      color: $red5;
    }
  }
  &.white {
    background-color: $white;
    .writeBackground {
      color: $grey4;
    }
  }
  .react-multi-carousel-list {
    height: -webkit-fill-available;
    .react-multi-carousel-track {
      height: inherit;
    }
  }
  .Carouselcard {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;

    .description {
      z-index: 1;
      margin: auto;
      width: 100%;
      text-align: center;
      padding: 0px 20px;
      box-sizing: border-box;
      h3 {
        color: $black;
        margin-bottom: 10px;
      }
      p {
        color: $grey3;
        margin-bottom: 10px;
      }
    }
    .image {
      z-index: 1;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center !important;
      height: 75vh;
    }
  }
  .react-multi-carousel-dot-list {
    transform: rotate(90deg);
    bottom: auto;
    left: auto;
    .react-multi-carousel-dot {
      button {
        border-style: none;
        background-color: transparent;
        box-shadow: inset 0px 2.45783px 2.45783px rgba(0, 0, 0, 0.25);
      }

      &.react-multi-carousel-dot--active {
        button {
          background-color: $black4;
        }
      }
    }
  }
  @include gt-sm {
    padding: 2% 0 2% 0;
    .writeBackground {
      display: flex;
      align-items: center;
      font-size: 250px;
      // line-height: 225%;
      transform: translateX(0%) rotate(0);
    }
    .react-multi-carousel-list {
      height: fit-content;
      .react-multi-carousel-track {
        height: inherit;
      }
    }
    .Carouselcard {
      flex-direction: row;
      // padding-left: 75px;
      // padding-right: 75px;
      .image {
        width: 50%;
        background-position-x: left;
        height: 90vh;
      }
      .description {
        width: 50%;
        text-align: left;
        padding: 0px 125px;
        h3 {
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 15px;
        }
      }
    }
  }
  @include gt-md {
    .writeBackground {
      font-size: 315px;
      // line-height: 190%;
    }
    .Carouselcard {
      .image {
      }
    }
  }
  @include gt-lg {
    .writeBackground {
      font-size: 420px;
      // line-height: 145%;
    }
  }
  @include gt-xl {
    .writeBackground {
      font-size: 590px;
      // line-height: 135%;
    }
    .Carouselcard {
      // padding-left: 150px;
      // padding-right: 150px;
      .description {
        padding: 0px 250px;
        h3 {
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  & .shop-container {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    margin-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    overflow: visible;
    position: relative;

    @include gt-md {
      max-width: 1280px;
    }
    @include gt-xl {
      max-width: 1280px;
    }
  }

  &.shop-hide {
    overflow: visible;
    &.target-m {
      display: block;
      @include gt-md {
        display: none;
      }
      @include gt-xl {
        display: none;
      }
    }

    &.target-d {
      display: none;
      @include gt-md {
        display: block;
      }
      @include gt-xl {
        display: block;
      }
    }
  }
}

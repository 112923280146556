@import "../../../../../assets/variables";
@import "../../../../../assets/mixins";

.shop {
  &.catalogo-item {
    width: 50%;
    box-sizing: border-box;
    padding: 8px;
    display: inline-block;
    overflow: visible;
    margin-bottom: 72px;

    @media only screen and (max-device-width: 812px) and (orientation: portrait) {
      padding: 0px;
    }

    &:nth-child(odd) {
      padding-left: 0px;

      @include gt-md {
        padding-left: 0.6vw;
      }
      @include gt-xl {
        padding-left: 0.6vw;
      }
      @media only screen and (max-device-width: 812px) and (orientation: portrait) {
        padding-right: 6px;
      }

      & .wrapper {
        & .catalogo-header {
          border-radius: 24px 24px 0px 100px;

          @include gt-md {
            border-radius: 24px 24px 116px 0px;
          }
          @include gt-xl {
            border-radius: 24px 24px 116px 0px;
          }
          @media only screen and (max-device-width: 812px) and (orientation: portrait) {
            border-radius: 24px 24px 0px 64px;
          }
        }
      }
    }

    &:nth-child(even) {
      padding-right: 0px;

      @include gt-md {
        padding-right: 0.6vw;
      }
      @include gt-xl {
        padding-right: 0.6vw;
      }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
        padding-left: 6px;
      }
    }

    @include gt-md {
      width: 25%;
      padding: 0.6vw;
    }
    @include gt-xl {
      width: 25%;
      padding: 0.6vw;
    }

    & .wrapper {
      width: 100%;
      margin-top: 24px;
      padding: 0;
      padding-bottom: 12px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 7px 14px rgba(118, 133, 137, 0.15);
      overflow: visible;

      @include gt-md {
        border-radius: 24px;
      }
      @include gt-xl {
        border-radius: 24px;
      }

      & .new {
        width: 40px;
        height: 40px;
        background-color: $red;
        position: relative;
        z-index: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        & p {
          font-size: 10px;
          font-weight: 600;
          color: white;

          @include gt-md {
            font-size: 16px;
            font-weight: 600;
          }
          @include gt-xl {
            font-size: 16px;
            font-weight: 600;
          }
          @media only screen and (max-device-width: 812px) and (orientation: portrait) {
            font-size: 10px;
          }
        }

        @include gt-md {
          width: 52px;
          height: 52px;
          margin-top: -24px;
          margin-left: -24px;
        }
        @include gt-xl {
          width: 52px;
          height: 52px;
          margin-top: -24px;
          margin-left: -24px;
        }
        @media only screen and (max-device-width: 812px) and (orientation: portrait) {
          width: 32px;
          height: 32px;
          margin-top: -26px;
          margin-left: -18x;
        }
      }

      & .catalogo-header {
        background-color: white;
        box-shadow: inset 0px -12px 24px rgba(203, 203, 203, 0.12);
        border-radius: 24px 24px 100px 0px;
        width: 100%;
        height: 194px;
        margin: 0;
        overflow: visible;

        @include gt-md {
          height: 280px;
          border-radius: 24px 24px 0px 116px;
        }
        @include gt-xl {
          height: 280px;
          border-radius: 24px 24px 0px 116px;
        }
        @media only screen and (max-device-width: 812px) and (orientation: portrait) {
          height: 132px;
          border-radius: 24px 24px 64px 0px;
        }

        & .flex-h-space-btw {
          padding: 0px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          z-index: 100;

          & .flex-icon {
            width: 32px;
            height: 32px;
            margin: 4px;
            object-fit: contain;

            @include gt-md {
              width: 48px;
              height: 48px;
              margin: 0px;
            }
            @include gt-xl {
              width: 48px;
              height: 48px;
              margin: 0px;
            }
          }
        }

        & .flex-prod-image {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          top: -56px;
          left: 0;

          @include gt-md {
            top: -72px;
          }
          @include gt-xl {
            top: -72px;
          }
          @media only screen and (max-device-width: 812px) and (orientation: portrait) {
            top: -58px;
          }

          & .prodImage {
            width: 100%;
            height: 250px;
            min-width: 300px;
            object-fit: contain;
            object-position: center center;

            @include gt-md {
              min-width: 350px;
              height: 350px;
            }
            @include gt-xl {
              min-width: 350px;
              height: 350px;
            }
            @media only screen and (max-device-width: 812px) and (orientation: portrait) {
              height: 190px;
              min-width: 280px;
            }
          }
        }
      }

      & .brand-label {
        margin-top: 12px;
        margin-bottom: -6px;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;

        @include gt-md {
          margin-top: 32px;
          padding-bottom: 0px;
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 16px;
        }
        @include gt-xl {
          margin-top: 32px;
          padding-bottom: 0px;
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 16px;
        }
        @media only screen and (max-device-width: 812px) and (orientation: portrait) {
          font-size: 12px;
          margin-bottom: -8px;
        }
      }

      & .name-label {
        margin-top: -2px;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        padding-right: 8px;
        padding-left: 8px;

        @include gt-md {
          padding-bottom: 6px;
          font-size: 24px;
          font-weight: 600;
        }
        @include gt-xl {
          padding-bottom: 6px;
          font-size: 24px;
          font-weight: 600;
        }
        @media only screen and (max-device-width: 812px) and (orientation: portrait) {
          font-size: 15px;
        }
      }

      & .details-label {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }

      & .price-label {
        margin-top: 24px;
        font-size: 20px;
        font-weight: 600;
        opacity: 1;
        text-align: center;

        @include gt-md {
          margin-top: 64px;
          padding-bottom: 0px;
          font-size: 30px;
          font-weight: 500;
        }
        @include gt-xl {
          margin-top: 64px;
          padding-bottom: 0px;
          font-size: 30px;
          font-weight: 500;
        }

        @media only screen and (max-device-width: 812px) and (orientation: portrait) {
          font-size: 16px;
          margin-top: 18px;
        }

        &.last {
          margin-bottom: 4px;

          @include gt-md {
            margin-bottom: 16px;
          }
          @include gt-xl {
            margin-bottom: 16px;
          }

          &.old {
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: 20px;
            margin-top: 30px;
            opacity: 1;
            color: $red;

            @media only screen and (max-device-width: 812px) and (orientation: portrait) {
              font-size: 12px;
              margin-top: 0px;
            }

            & .line {
              text-decoration: line-through;
              color: $black;
            }
          }
        }
      }

      & .price-label-taxes {
        margin-top: 4px;
        font-size: 10px;
        font-weight: 500;
        opacity: 1;
        text-align: center;
        padding-bottom: 8px;

        @include gt-md {
          padding-bottom: 14px;
        }
        @include gt-xl {
          padding-bottom: 14px;
        }
      }

      & .flex-h-center {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: px;
      }

      & .prod-add-to-cart {
        box-sizing: border-box;
        width: 70%;
        margin-top: 8px;
        margin-left: 15%;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 10px;
        padding-bottom: 12px;
        background-color: $grey;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s;
        margin-bottom: 16px;

        &:hover {
          background-color: $red;

          p {
            color: #fff;
          }

          & .prod-add-to-cart-icon {
            filter: invert(0%);

            & path,
            & g {
              fill: white;
              stroke: white;
            }
          }
        }

        @include gt-md {
          width: 60%;
          margin-left: 20%;
          padding-left: 18px;
          padding-right: 18px;
          padding-top: 14px;
          padding-bottom: 16px;
          border-radius: 14px;
        }

        @include gt-xl {
          width: 60%;
          margin-left: 20%;
          padding-left: 18px;
          padding-right: 18px;
          padding-top: 14px;
          padding-bottom: 16px;
          border-radius: 14px;
        }

        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
          width: 72%;
          margin-left: 14%;
          margin-bottom: 10px;
          margin-top: 2px;
          padding-left: 14px;
          padding-right: 14px;
          padding-top: 9px;
          padding-bottom: 11px;
          border-radius: 8px;
        }

        & p {
          font-size: 12px;
          color: #3f3f3f;
          font-weight: 700;
          margin-right: 12px;
          text-transform: uppercase;

          @include gt-md {
            font-size: 18px;
            font-weight: 500;
          }
          @include gt-xl {
            font-size: 18px;
            font-weight: 500;
          }

          @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
            font-size: 10px;
          }
        }

        & .prod-add-to-cart-icon {
          width: 17px;
          height: 17px;
          object-fit: contain;
          object-position: center center;
          filter: invert(100%);
          fill: #3f3f3f;
          margin-top: -2px;

          @include gt-md {
            width: 24px;
            height: 24px;
          }
          @include gt-xl {
            width: 24px;
            height: 24px;
          }
          @media only screen and (max-device-width: 812px) and (orientation: portrait) {
            width: 16px;
            height: 16px;
          }

          & path,
          & g {
            fill: #3f3f3f;
            stroke: #3f3f3f;
          }
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.OrderKo {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  & .cta {
    width: 100%;
    margin-top: 8px;
    padding-top: 12px;
    padding-bottom: 14px;
    background-color: $red;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s;
    margin-bottom: 24px;
    box-sizing: border-box;
    margin-top: 32px;

    &:hover {
      opacity: 0.7;
    }

    & p {
      font-size: 16px;
      color: white;
      font-weight: 500;
      margin-right: 8px;
      text-transform: uppercase;
    }
  }
}

$white: #f2f2f2;
$white2: #ffffff;
$black: #3f3f3f;
$black2: #232323;
$black3: #0f0f0f;
$black4: #262626;
$black5: #292c30;
// $red: #eb2d38;
$red: #ff002d;
$red2: #eb4b4b;
$red3: #b5212a;
$red4: #ea444e;
$red5: #e12a35;
$grey: #f5f6f8;
$grey2: #bdbdbd;
$grey3: #828282;
$grey4: #e0e0e0;
$yellow1: #ffbd35;
$yellow2: #ffa80e;

$breakpoints: (
  xs: 370,
  sm: 767,
  md: 1023,
  lg: 1279,
  xl: 1919,
);

$sidePadding: (
  xs: 20px,
  sm: 11.8%,
  md: 5%,
  lg: 7.5%,
  xl: 7.5%,
);

$parallaxHeightSectionSize: (
  xs: 0px,
  sm: 0px,
  md: 0px,
  lg: 0px,
  xl: 0px,
);

@import "../../../../../assets/variables";
@import "../../../../../assets/mixins";

.informazione {
  & .section-title {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 500;
  }

  & .info-user {
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
  }

  & .logout {
    font-size: 16px;
    font-weight: 400;
    color: #828282, 100%;
  }

  & .check {
    margin-top: 24px;

    & label {
      font-size: 16px;
      font-weight: 400;
      color: #828282, 100%;
    }
  }
}

.pagamento {
  & .indirizzo-fatturazione-check {
    margin-top: 0px;

    input[type="radio"] {
      margin-top: 0px;
    }
  }
}

.informazione,
.spedizione,
.pagamento {
  .info-box {
    border: 1px solid #bdbdbd;
    border-radius: 16px;
    padding: 16px;
    margin-top: 44px;

    @include gt-md {
      padding: 24px;
    }

    @include gt-xl {
      padding: 24px;
    }

    & .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      padding: 0;

      & h3 {
        width: 50px;
        font-size: 13px;
        font-weight: 300;
        margin-right: 16px;
        margin-bottom: 0px !important;

        @include gt-md {
          font-size: 14px;
          margin-right: 64px;
        }

        @include gt-xl {
          font-size: 14px;
          margin-right: 64px;
        }
      }

      & p {
        font-size: 13px !important;
        font-weight: 500;
        flex-grow: 1;

        @include gt-md {
          font-size: 18px;
        }

        @include gt-xl {
          font-size: 18px;
        }
      }
    }

    & .divider {
      width: 100%;
      height: 1px;
      background-color: #bdbdbd;
      margin-top: 18px;
      margin-bottom: 18px;
    }
  }

  & .headlineSpedizione {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 500;
  }

  & input[type="radio"] {
    margin-top: 24px;
  }

  & label {
    margin-left: 13px;
    font-size: 18px;
    font-weight: 500;
  }
}

.shop.checkoutPage .shop.shop-navbar,
.shop.checkoutPage .under-bar {
  display: none;
}

.shop.checkoutPage .footer {
  display: none;
}

.shop {
  & .listOfBrandsByName {
    & h3 {
      font-size: 32px;
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: 24px;
    }

    & p {
      font-size: 16px;
      margin: 24px;
      margin-left: 24px;
      cursor: pointer;
    }

    & .divider {
      content: "";
      width: 100%;
      height: 1px;
      background-color: black;
      margin-top: 16px;
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  & .boxPage {
    & .title {
      font-size: 32px;
      font-weight: 800;
      padding-top: 54px;

      @include gt-md {
        font-size: 48px;
        font-weight: 800;
        padding-top: 72px;
      }
      @include gt-xl {
        font-size: 48px;
        font-weight: 800;
        padding-top: 72px;
      }

      &.minus {
        padding-top: 0px;
      }
    }
    & p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.6;
      color: #000000;
      box-sizing: border-box;
      padding-top: 16px;
      padding-bottom: 72px;
    }

    & .flex {
      display: block;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      @include gt-md {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
      @include gt-xl {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      & .box {
        width: 96%;
        height: 100%;
        background-color: white;
        box-shadow: 0px 5px 11px rgba(118, 133, 137, 0.15);
        border-radius: 16px;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        margin: 8px;
        padding-bottom: 8px;

        @include gt-md {
          width: 50%;
          margin: 8px;
        }
        @include gt-xl {
          width: 23%;
          margin: 8px;
        }

        &:nth-child(even) .image {
          border-radius: 0px 0px 54px 0px;
        }
        &:nth-child(odd) .image {
          border-radius: 0px 0px 0px 54px;
        }

        & .image {
          width: 100%;
        }

        & h2 {
          font-size: 32px;
          font-weight: 400;
          line-height: 1.2;
          color: #000000;
          box-sizing: border-box;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 16px;
          font-family: "Shrimp";
        }

        & h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 1.2;
          color: #828282;
          box-sizing: border-box;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 0px;
          padding-bottom: 8px;
        }

        & p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          color: #000000;
          box-sizing: border-box;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 8px;
          padding-bottom: 16px;
        }
      }
    }
  }
}

@import "../../../assets/variables";
@import "../../../assets/mixins";

.Partners {
  padding: 38px 20px 20px 20px;
  background-color: $white2;
  h3 {
    text-align: left;
    margin-bottom: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 102.52%;
    font-size: 20px;
  }
  .row {
    @include row-field(3);
    justify-content: space-between;
    > * {
      width: 30%;
    }
    height: 15vh;
    .image {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      box-sizing: border-box;
      height: inherit;
    }
  }

  @include gt-sm {
    height: 100vh;
    box-sizing: border-box;
    padding: 10vh 5vw 10vh 5vw;
    h3 {
      text-align: left;
      font-size: 20px;
      margin-bottom: 10vh;
      // margin-bottom: 50px;
    }
    .row {
      @include row-field(4);
      // height: 80px;
      // margin-bottom: 30px;
      height: 15vh;
      margin-bottom: 10vh;
    }
  }
  @include gt-md {
    padding: 10vh 5% 10vh 5%;
    h3 {
      font-size: 25px;
      // margin-bottom: 50px;
    }
    .row {
      // height: 100px;
      // margin-bottom: 40px;
    }
  }
  @include gt-lg {
    padding: 10vh map-get($sidePadding, lg) 10vh map-get($sidePadding, lg);
    h3 {
      font-size: 30px;
      // margin-bottom: 70px;
    }
    .row {
      // height: 120px;
      // margin-bottom: 50px;
    }
  }
  @include gt-xl {
    h3 {
      font-size: 40px;
      // margin-bottom: 100px;
    }
    .row {
      // height: 150px;
      // margin-bottom: 50px;
      // .image {
      //   height: 120px;
      // }
    }
  }
}

@import "../../../assets/mixins";
@import "../../../assets/variables";

.AboutUs {
  padding: 40px map-get($sidePadding, xs) 50px map-get($sidePadding, xs);
  text-align: left;
  background-color: $white2;
  margin-bottom: map-get($parallaxHeightSectionSize, xs);
  p {
    font-weight: 300;
    font-size: 15px;
    white-space: break-spaces;
    color: $grey3;
    @include gt-sm {
      font-size: 12px;
      line-height: 150%;
    }
    @include gt-md {
      font-size: 16px;
      line-height: 150%;
    }
    @include gt-lg {
      font-size: 22px;
    }
  }
  .top-row {
    .left-column {
      margin-bottom: 40px;
      h1 {
        font-size: 42px;
        margin-bottom: 12px;
        color: $black;
        span {
          color: $red;
        }
      }
      h3 {
        font-size: 24px;
        // margin-bottom: 1%;
        line-height: 130%;
        color: $black;
      }
    }
    .right-column {
      box-sizing: border-box;
      text-align: left;
      p {
        margin-bottom: 55px;
        font-size: 16px;
      }
    }
  }
  .bottom-row {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -25px;
    .left-column {
      box-sizing: border-box;
      h4 {
        font-weight: 800;
        font-size: 20px;
        line-height: 102%;
        margin-bottom: 22px;
        color: $black;
      }
      p.first-paragraph {
        font-size: 16px;
        margin-bottom: 30px;
      }
      .info-block {
        display: flex;
        &.first-block {
          margin-bottom: 30px;
        }
        .icon-container {
          box-shadow: 0px 4px 8.5px rgba(118, 133, 137, 0.15);
          border-radius: 14px;
          height: fit-content;
          padding: 3%;
          box-sizing: border-box;
          position: relative;
          display: none;
        }
        .text-container {
          h5 {
            font-weight: 800;
            line-height: 102%;
            margin-bottom: 10px;
            color: $grey3;
          }
          p {
            font-weight: 200;
            line-height: 150%;
            font-size: 16px;
            margin-bottom: 10px;
          }
          a {
            cursor: pointer;
            color: $red;
            font-weight: 500;
            line-height: 120%;
            font-size: 15px;
          }
        }
      }
    }
    .map {
      height: 350px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 23px;
    }
  }
  @include gt-sm {
    padding: 7% 4% 10% 4%;
    text-align: left;
    margin-bottom: map-get($parallaxHeightSectionSize, sm);
    .top-row {
      @include row-field();
      padding-bottom: 12%;
      .left-column {
        margin-bottom: 0px;
        h1 {
          margin-bottom: 7%;
          line-height: 100%;
        }
        h3 {
          font-size: 24px;
          line-height: 100%;
          margin-bottom: 1%;
          // line-height: 120%;
        }
      }
      .right-column {
        padding-left: 7%;
        padding-right: 2.5%;
        p {
          margin-bottom: 7%;
        }
      }
    }
    .bottom-row {
      display: block;
      @include row-field();
      margin-top: auto;
      .left-column {
        padding-right: 5%;
        h4 {
          font-size: 20px;
          margin-bottom: 4%;
        }
        p.first-paragraph {
          margin-bottom: 8%;
        }
        .info-block {
          &.first-block {
            margin-bottom: 8%;
          }
          .icon-container {
            display: block;
            min-width: 41px;
            height: 41px;
            border-radius: 9px;
            .icon-search {
              width: -webkit-fill-available;
              height: -webkit-fill-available;
            }
            .icon-termometro {
              width: -webkit-fill-available;
              height: -webkit-fill-available;
            }
          }
          .text-container {
            margin-left: 5%;
            h5 {
              font-size: 16px;
              margin-bottom: 4%;
            }
            p {
              font-size: 12px;
              margin-bottom: 2%;
            }
            a {
              font-size: 10px;
            }
          }
        }
      }
      .map {
        height: auto;
        margin-bottom: 0px;
      }
    }
  }
  @include gt-md {
    padding: 7% 5% 10% 7.5%;
    .top-row {
      .left-column {
        h1 {
          font-size: 48px;
        }
        h3 {
          font-size: 27px;
        }
      }
      .right-column {
        padding-right: 3.2%;
        p {
          font-size: 12px;
        }
      }
    }
    .bottom-row {
      .left-column {
        padding-right: 8%;
        h4 {
          font-size: 21px;
        }
        p.first-paragraph {
          font-size: 12px;
          margin-bottom: 10%;
        }
        .info-block {
          .icon-container {
            min-width: 35px;
            height: 35px;
            border-radius: 8px;
            &.termometro {
              padding: 2% 3%;
            }
            .icon-search {
              width: -webkit-fill-available;
              height: -webkit-fill-available;
            }
            .icon-termometro {
              width: -webkit-fill-available;
              height: -webkit-fill-available;
            }
          }
          .text-container {
            h5 {
              font-size: 18px;
            }
            p {
              font-size: 12px;
            }
            a {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  @include gt-lg {
    .top-row {
      .left-column {
        h1 {
          font-size: 67px;
          margin-bottom: 30px;
        }
        h3 {
          font-size: 37px;
          margin-bottom: 1%;
        }
      }
      .right-column {
        p {
          font-size: 16px;
        }
      }
    }
    .bottom-row {
      .left-column {
        padding-right: 10%;
        h4 {
          font-size: 29px;
        }
        p.first-paragraph {
          font-size: 18px;
          margin-bottom: 10%;
        }
        .info-block {
          .icon-container {
            min-width: 50px;
            height: 50px;
            &.termometro {
              // padding: 2% 3%;
            }
            .icon-search {
              // width: 62px;
              // height: 62px;
            }
            .icon-termometro {
              width: 25.3px;
              height: 30px;
            }
          }
          .text-container {
            h5 {
              font-size: 22px;
            }
            p {
              font-size: 16px;
            }
            a {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  @include gt-xl {
    margin-bottom: map-get($parallaxHeightSectionSize, xl);
    .top-row {
      .left-column {
        h1 {
          font-size: 88px;
        }
        h3 {
          font-size: 50px;
          margin-bottom: 1%;
        }
      }
      .right-column {
        p {
          font-size: 22px;
        }
      }
    }
    .bottom-row {
      .left-column {
        h4 {
          font-size: 40px;
        }
        p.first-paragraph {
          font-size: 22px;
          margin-bottom: 8%;
        }
        .info-block {
          .icon-container {
            min-width: 60px;
            height: 60px;
            border-radius: 14px;
            &.termometro {
              padding: 2.5% 3%;
            }
          }
          .text-container {
            h5 {
              font-size: 26px;
            }
            p {
              font-size: 20px;
            }
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@import "../../../../assets/variables";
@import "../../../../assets/mixins";

.shop {
  & .under-bar {
    padding-top: 64px;
  }
  &.shop-navbar {
    margin: 0;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    & .body {
      padding-top: 20px;
      padding-bottom: 16px;

      & ul {
        list-style-type: none;
        & li {
          margin-left: 20px;
          margin-right: 20px;
          font-weight: 600;
          float: left;
          cursor: pointer;
          transition: opacity 0.4s;
          outline-style: none;
          -webkit-touch-callout: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          color: #4f4f4f;

          &.dropdown {
            opacity: 1;
            cursor: default;

            & span {
              cursor: pointer;
            }

            &:hover {
              opacity: 1;
            }
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      cursor: pointer;
      transition: opacity 0.4s;
      display: inline-block;

      &.lastOnRight {
        margin-right: 0px;
      }

      &:hover {
        opacity: 0.4;
      }
    }

    & .logo {
      cursor: pointer;
      height: 40px;
      margin-top: -4px;
    }
  }

  .shop-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .shop-v-middle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .shop-v-space-between {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
  }

  .shop-row-space-between {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .shop-mobile-menu-shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.5s;

    &.hide-mobile-menu {
      background-color: rgba(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .shop-cart-menu-shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.5s;

    &.hide-cart-menu {
      background-color: rgba(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .shop-mobile-menu {
    position: fixed;
    width: 80vw;
    height: 100vh;
    background-color: white;
    left: 0;
    top: 0;
    transition: left 0.4s;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.hide-mobile-menu {
      left: -100%;
    }

    & .user-section {
      padding-top: 24px;
      padding-bottom: 16px;
      padding-left: 16px;

      & .user-avatar {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        background-color: #f1f1f1;
      }

      & p {
        margin-left: 16px;
        font-size: 14px;
        opacity: 0.6;
      }
      & h3 {
        margin-left: 16px;
        font-size: 25px;
      }
    }

    & ul {
      list-style: none;
      padding: 0;
      padding-left: 16px;

      & li {
        font-size: 25px;
        font-weight: 500;
        padding-top: 16px;
        padding-bottom: 16px;

        & p {
          font-size: 25px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }

        & .dropdownmobile {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          padding-right: 24px;

          & .dropdown-text {
            font-size: 25px;
            font-weight: 500;
          }

          & .dropdown-icon {
            width: 32px;
            height: 32px;
          }
        }

        & .dropdownmobile-menu {
          overflow: hidden;
          height: 0px;

          &.opened {
            height: auto;
          }

          & p {
            font-size: 20px;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              opacity: 0.6;
            }
          }

          & p a {
            color: black;
            font-weight: 700;
            text-decoration: none;
          }
        }
      }
    }

    & .social-section {
      padding-left: 16px;
    }
  }

  .shop-cart-menu {
    position: fixed;
    width: 85vw;
    height: 100vh;
    background-color: white;
    left: 15vw;
    transition: left 0.4s;
    display: block;
    z-index: 100000;

    @include gt-md {
      width: 500px;
      left: auto;
      right: 0;
    }
    @include gt-xl {
      width: 500px;
      left: auto;
      right: 0;
    }

    &.hide-cart-menu {
      display: none;

      & .cart-header-section {
        @include gt-md {
          right: auto;
        }
        @include gt-xl {
          right: auto;
        }
      }
    }

    h5 {
      margin-top: 16px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #bdbdbd;
      margin-top: 16px;
    }

    & .list-cart-menu {
      height: 60vh;
      overflow: scroll;
      padding-top: 60px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .cart-header-section {
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      width: 85vw;
      background-color: white;
      position: fixed;
      top: 0;

      @include gt-md {
        width: 500px;
        left: auto;
        right: 0;
      }
      @include gt-xl {
        width: 500px;
        left: auto;
        right: 0;
      }
    }

    & .cart-footer-section {
      padding-top: 24px;
      padding-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      width: 80vw;
      background-color: white;
      position: fixed;
      bottom: 0;

      .GenericInput {
        display: flex;
        flex-direction: row;
        .labelsRow {
          width: 100%;
        }
      }

      @include gt-md {
        width: 500px;
      }
      @include gt-xl {
        width: 500px;
      }

      & .title {
        font-size: 18px;
        font-weight: 600;
      }

      & .no-iva {
        font-size: 12x;
        font-weight: 400;
        opacity: 0.7;
      }

      & .price {
        font-size: 18px;
        font-weight: 600;
      }

      & .button {
        margin-top: 24px;
        height: 54px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        color: $white;
        border-radius: 6px;
        transition: all 0.3s;

        &.active {
          background-color: $red;
          cursor: pointer;

          p {
            color: white;
          }
        }

        p {
          font-weight: 600;
          font-size: 16px;
          color: #3f3f3f;
        }
      }
    }
  }

  .shop-profile-menu {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    top: 68px;

    &.hide-profile-menu {
      display: none;
    }

    & .list-profile-menu {
      margin: 0;
      padding: 0;
      margin-right: 16px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      padding: 16px;
      right: 0;

      & li {
        margin: 16px;
        margin-left: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          opacity: 0.6;
        }

        & .icon {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }

        & p {
          font-size: 16px;
        }
      }
    }
  }

  .shop-drop-down-menu {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    top: 68px;
    left: 0;

    &:hover {
      opacity: 1;
    }

    &.hide-drop-down-menu {
      display: none;
    }

    & .list-drop-down-menu {
      margin: 0;
      padding: 0;
      left: 20%;
      margin-right: 0px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      padding: 40px;
      padding-left: 88px;
      padding-top: 40px;
      padding-right: 0px;
      position: relative;
      width: 60%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      & .top20 {
        display: block;
        width: 100%;
        margin-left: 16px;
        text-decoration: underline;
        padding-bottom: 16px;
        font-size: 24px;

        & a {
          color: black;
          text-decoration: none;
        }
      }

      & li {
        flex-basis: 25%;
        flex-grow: 0;
        margin: 16px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;

        &:hover {
          opacity: 0.4;
        }

        & .icon {
          width: 24px;
          height: 24px;
          background-color: red;
        }

        & p {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.2;

          & a {
            color: black;
            text-decoration: none;
          }
        }
      }
    }
  }

  .cartCount {
    position: absolute;
    background-color: $red;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4px;
    margin-left: -4px;

    & p {
      font-size: 8px;
      color: white;

      @include gt-md {
        font-size: 12px;
      }
      @include gt-xl {
        font-size: 12px;
      }
    }
  }

  & .globalSearch {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000000;
    top: 0;
    left: 0;

    .shadow {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }

    & .searchBody {
      position: relative;
      background-color: white;
      padding-top: 24px;
      padding-bottom: 24px;
      top: 0;
      left: 0;

      & .flex-space-btw {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & input {
          border: 0px;
          font-size: 16px;
          font-weight: 400;
          box-sizing: border-box;
          padding-left: 16px;
          padding-right: 16px;
          flex-grow: 1;
          outline: none;
        }

        & img {
          width: 24px;
          height: 24px;
          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@import "../../assets/variables";
@import "../../assets/mixins";
@import "../../assets/icons";
.menu {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  height: 70px;
  right: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  align-items: center;
  padding-right: map-get($sidePadding, xs);
  padding-left: map-get($sidePadding, xs);
  .logo {
    cursor: pointer;
    img {
      height: 40px;
    }
  }
  .rightDiv {
    margin-left: auto;
    align-self: center;
    .icon-menu {
      float: right;
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }
  @include gt-sm {
    .logo {
      img {
        height: 26px;
      }
    }
    .rightDiv {
      .icon-menu {
        height: 20px;
        width: 20px;
      }
    }
  }
  @include gt-md {
    padding-right: map-get($sidePadding, md);
    padding-left: map-get($sidePadding, md);
    .logo {
      img {
        height: 33px;
      }
    }
  }
  @include gt-lg {
    padding-right: map-get($sidePadding, lg);
    padding-left: map-get($sidePadding, lg);
    .logo {
      img {
        height: 40px;
      }
    }
    .rightDiv {
      .icon-menu {
        height: 27px;
        width: 27px;
      }
    }
  }
  @include gt-xl {
    padding-right: map-get($sidePadding, xl);
    padding-left: map-get($sidePadding, xl);
    .logo {
      img {
        height: 45px;
      }
    }
    .rightDiv {
      .icon-menu {
        height: 33px;
        width: 33px;
      }
    }
  }
}

.landingFooter {
  padding: 20px;
  text-align: center;
  .col {
    box-sizing: border-box;
    h4 {
      font-weight: 800;
      color: $black;
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 15px;
    }
    h5 {
      font-weight: 600;
      line-height: 102%;
      color: $grey3;
      font-size: 18px;
    }
    h6 {
      font-weight: 300;
      line-height: 102%;
      color: $grey3;
      font-size: 15px;
    }

    &.leftCol {
      margin-bottom: 20px;
      .footerLogo {
        // background-size: contain;
        // background-position: center;
        height: 60px;
        // background-repeat: no-repeat;
        // width: inherit;
        margin-bottom: 10%;
      }
      h6 {
        margin-bottom: 12px;
      }
      .row {
        display: flex;
        justify-content: center;
        .eurosaga {
          width: 25px;
          height: 25px;
        }
      }
    }
    &.centerCol {
      margin-bottom: 20px;
      h6 {
        margin-bottom: 12px;
      }
    }
    &.rightCol {
      margin-bottom: 20px;
      box-sizing: border-box;
      h5 {
        cursor: pointer;
        margin-bottom: 8px;
      }
    }
  }
  hr {
    width: 100%;
  }
  p {
    text-align: center;
    width: -webkit-fill-available;
    color: $grey3;
    font-weight: 300;
    line-height: 250%;
  }
  @include gt-sm {
    @include row-field(3);
    text-align: left;
    padding: 9% 3% 9% 5%;
    .col {
      h4 {
        font-size: 22px;
        line-height: 130%;
        margin-bottom: 10%;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 15px;
      }

      &.leftCol {
        margin-bottom: 5%;
        .footerLogo {
          height: 30px;
        }
        h6 {
          margin-bottom: 10%;
          line-height: 165%;
        }
        .row {
          justify-content: left;
          .eurosaga {
            width: 20px;
            height: 20px;
            &.icon-facebook {
              margin-right: 10px;
            }
          }
        }
      }
      &.centerCol {
        padding: 0;
        width: auto;
        margin: 0 auto;
        h5 {
          margin-bottom: 4%;
        }
        h6 {
          margin-bottom: 14%;
        }
      }
      &.rightCol {
        width: auto;
        margin-left: auto;
        h5 {
          margin-bottom: 14%;
        }
      }
    }
    hr {
      margin-bottom: 4.5%;
    }
    p {
      font-size: 15px;
    }
  }
  @include gt-md {
    padding: 9% 5%;
    .col {
      h4 {
        font-size: 22px;
      }
      h5 {
        font-size: 20px;
      }
      h6 {
        font-size: 18px;
      }
      &.leftCol {
        margin-bottom: 5%;
        .footerLogo {
          height: 32px;
        }
        .row {
          .eurosaga {
            width: 25px;
            height: 25px;
            &.icon-facebook {
              margin-right: 20px;
            }
          }
        }
      }
      &.centerCol {
        h4 {
          margin-bottom: 40px;
        }
        h5 {
          margin-bottom: 8%;
        }
        h6 {
          margin-bottom: 20%;
        }
      }
      &.rightCol {
        h4,
        h5 {
          margin-bottom: 40px;
        }
      }
    }
    p {
      font-size: 18px;
    }
  }
  @include gt-lg {
    padding: 9% map-get($sidePadding, lg);
    .col {
      h4 {
        font-size: 32px;
      }
      h5 {
        font-size: 26px;
      }
      h6 {
        font-size: 22px;
      }
      &.leftCol {
        margin-bottom: 50px;
        .row {
          .eurosaga {
            &.icon-facebook {
              margin-right: 20px;
            }
            &.icon-facebook,
            &.icon-instagram {
              height: 25px;
              width: 25px;
            }
          }
        }
      }
      &.centerCol {
      }
      &.rightCol {
        h4,
        h5 {
          margin-bottom: 45%;
        }
      }
    }
  }
  @include gt-xl {
    padding: 9% map-get($sidePadding, xl);
    .col {
      h4 {
        font-size: 38px;
      }
      h5 {
        font-size: 30px;
      }
      h6 {
        font-size: 27px;
      }
      &.leftCol {
        margin-bottom: 4%;
        .footerLogo {
          height: 50px;
          margin-bottom: 40px;
        }
        .row {
          .eurosaga {
            &.icon-facebook,
            &.icon-instagram {
              width: 35px;
              height: 35px;
            }
            &.icon-facebook {
              margin-right: 30px;
            }
          }
        }
      }
      &.centerCol {
      }
      &.rightCol {
        h4,
        h5 {
          margin-bottom: 40%;
        }
      }
    }
    p {
      font-size: 22px;
    }
  }
}

.landingFooterMobile {
  padding: 30px map-get($sidePadding, xs) 30px map-get($sidePadding, xs);
  .section {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      h6 {
        font-size: 18px;
        font-weight: normal;
      }
      .eurosaga {
        width: 10px;
        height: 10px;
      }
    }
    .expansionSection {
      margin-bottom: 32px;
      h5 {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 25px;
        color: $grey3;
      }
      h6 {
        font-size: 16px;
        font-weight: normal;
        word-wrap: break-word;
        margin-bottom: 20px;
        line-height: 140%;
      }
      .row-3-col {
        &.offices {
          color: $grey3;
        }
        // @include row-field(3);
      }
    }
  }
  hr {
    color: $black;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .iconsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    img {
      height: 35px;
    }
    .icons {
      display: flex;
      .eurosaga {
        width: 20px;
        height: 20px;
        &.icon-facebook {
          margin-right: 10px;
        }
      }
    }
  }
  p {
    font-size: 12px;
  }
}
